/**
 * Created by Giannis Kallergis on 2018-12-29
 */

import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

/** Components */
import Layout from "../components/Layout";
import Wrapper from "../components/Wrapper";
import SEO from "../components/SEO";
import Header from "../components/Header";
import Content from "../components/Content";
import SectionTitle from "../components/SectionTitle";

/** Components */
import TagsContainer from "../components/TagsContainer";
import AuthorHeader from "../components/AuthorHeader";

export default class TagsPage extends React.PureComponent {
  static propTypes = {
    data: PropTypes.shape({
      allMarkdownRemark: PropTypes.shape({
        group: PropTypes.arrayOf(
          PropTypes.shape({
            fieldValue: PropTypes.string.isRequired,
            totalCount: PropTypes.number.isRequired,
          })
        ).isRequired,
      }).isRequired,
    }).isRequired,
  };

  /** Renderers */

  render() {
    const tags = this.props.data.allMarkdownRemark.group || [];
    return (
      <Layout>
        <SEO title="Tags" />
        <Wrapper>
          <Header>
            <AuthorHeader />
          </Header>
          <Content hasHeader>
            <SectionTitle>Tags</SectionTitle>
            <TagsContainer
              tags={tags.map(t => ({ name: t.fieldValue, totalCount: t.totalCount }))}
              showTitle={false}
              showArticlesCount
            />
          </Content>
        </Wrapper>
      </Layout>
    );
  }
}

// noinspection JSUnusedGlobalSymbols
export const TagsPageQuery = graphql`
  query TagsPageQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date] }
      filter: { frontmatter: { draft: { ne: true } } }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
